
import { defineComponent } from "vue";
import { Form } from "vee-validate";
import ElCurrencyInputNoSymbol from "@/components/financial/CurrencyInputNoSymbol.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "cash-flows",
  components: {
    ElCurrencyInputNoSymbol,
  },
  props: {
    widgetClasses: String,
  },
  data() {
    const openingBalances = {
      label: "Opening Balance",
      value: [],
      total : 0,
    };
    const statements = {
      cashInFlow: {
        label: "Cash In Flow",
        value: [],
        total: 0,
        child: {
          fromSales: {
            label: "From Sales",
            value: [],
            total: 0,
            child: {
              cashSales: {
                label: "Cash Sales",
                value: [],
                total: 0,
              },
              cashAdvanceByCustomer: {
                label: "Cash Advance by Customers",
                value: [],
                total: 0,
              },
              cashTradeDebtors: {
                label: "Cash by Trade Debtors",
                value: [],
                total: 0,
              },
            },
          },
          fromFinancingCapital: {
            label: "From Financing/ Capital",
            value: [],
            total: 0,
            child: {
              capitalContribution: {
                label: "Capital Contribution",
                value: [],
                total: 0,
              },
              loansGrantsReceivedfromBankGovernment: {
                label: "Loans / Grants Received from Bank / Goverment",
                value: [],
                total: 0,
              },
              otherIncomes: {
                label: "Other Incomes / Cash Inflows",
                value: [],
                total: 0,
              },
            },
          },
        },
      },
      totalCashInFlow: {
        label: "Total Cash In Flow",
        value: [],
        total: 0,
      },
      cashOutFlow: {
        label: "Cash Out Flow",
        value: [],
        total: 0,
        child: {
          material: {
            label: "Material / Stock Purchased",
            value: [],
            total: 0,
          },
          marketing: {
            label: "Marketing",
            value: [],
            total: 0,
          },
          salaries: {
            label: "Salaries, EPF, SOCSO",
            value: [],
            total: 0,
          },
          rental: {
            label: "Rental",
            value: [],
            total: 0,
          },
          electricity: {
            label: "Electricity, Water, Phone, Internet",
            value: [],
            total: 0,
          },
          storageFees: {
            label: "Storage Fees / Delivery Fees",
            value: [],
            total: 0,
          },
          generalExpenses: {
            label: "General Expenses / Others (e.g Stove, Cooking Pans)",
            value: [],
            total: 0,
          },
          insurances: {
            label: "Insurance",
            value: [],
            total: 0,
          },
          taxesZakat: {
            label: "Taxes / Zakat",
            value: [],
            total: 0,
          },
          purchaseFixedAssets: {
            label:
              "Purchased of Fixed Assets (Restaurant Furnitures, Food Truck)",
            value: [],
            total: 0,
          },
          loans: {
            label:
              "Loans/ Grant Repayment (e.g. TEKUN, AIM, Microfinance, etc.)",
            value: [],
            total: 0,
          },
          personalUsage: {
            label: "Personal Usage / Repayment of Capital",
            value: [],
            total: 0,
          },
        },
      },
      totalCashOutFlow: {
        label: "Total Cash Out Flow",
        value: [],
        total: 0,
      },
    };

    const netCashSurpluses = {
      label : "Net Cash Surplus/ (Deficit)",
      value : [],
      total : 0,
    };

    const dates = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "June",
      "July",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];
    const totalDays = 0;

    let isLoading = true;
    const route = useRoute();
    const store = useStore();
    const cstmr_id = route.params.customer_id ?? null;
    const fin_id = route.params.fin_id ?? null;
    const customer = {
      birthdate: "",
      company_id: 0,
      detail: {},
      email: "",
      facilities: [],
      gender: "",
      media: [],
      name: "",
      nric: "",
      phone: "",
      package: "",
      net_worth_secured_liabilities: [],
      net_worth_secured_total: [],
      net_worth_unsecured_liabilities: [],
      net_worth_unsecured_total: [],
    };

    return {
      router: useRouter(),
      isLoading,
      dates,
      statements,
      totalDays,
      openingBalances,
      netCashSurpluses,
      store,
      fin_id,
      cstmr_id,
      customer,
      finDetails: null,
      isFinReady: false,
    };
  },
  
  async mounted() {
    setCurrentPageBreadcrumbs("Statements of Cash Flows", [
      "Customer",
      "Financial Statements SME",
    ]);
    this.totalDays = this.dates.length;
    this.initDays();
    this.init();
  },

  methods: {
    init() {
      if (this.cstmr_id) {
        this.store.dispatch(Actions.GET_CUSTOMER, this.cstmr_id).then(() => {
          this.customer = this.store.getters.getCustomerData;
          this.isLoading = false;
        });
      }

      if (this.fin_id) {
        this.store
          .dispatch(Actions.GET_FINANCIAL_STATEMENT, this.fin_id)
          .then(() => {
            this.openingBalances = this.store.getters.getFinancialStatementData.raw.openingBalances;
            this.statements = this.store.getters.getFinancialStatementData.raw.statements;
            this.netCashSurpluses = this.store.getters.getFinancialStatementData.raw.netCashSurpluses;
          });
      }
    },

    initDays() {
      for (let index = 0; index < 12; index++) {
        this.openingBalances.value.push(0);
        this.statements.totalCashInFlow.value.push(0);
        this.statements.totalCashOutFlow.value.push(0);
        this.netCashSurpluses.value.push(0);
        this.statements.cashInFlow.value.push(0);
        this.statements.cashOutFlow.value.push(0);
      }
      // cash in flow
      for (let key of Object.keys(this.statements.cashInFlow.child)) {
        let cashInFlowChild = this.statements.cashInFlow.child[key].child;
        for (let keyChild of Object.keys(cashInFlowChild)) {
          for (let index = 0; index < this.dates.length; index++) {
            cashInFlowChild[keyChild].value.push(0);
          }
        }
      }

      //cash out flow
      for (let key of Object.keys(this.statements.cashOutFlow.child)) {
        let cashOutFlowChild = this.statements.cashOutFlow.child[key];
        for (
          let indexcashOutFlowChild = 0;
          indexcashOutFlowChild < this.dates.length;
          indexcashOutFlowChild++
        ) {
          cashOutFlowChild.value.push(0);
        }
      }
    },

    calculateOpeningBalance() {
      for (let i = 0; i < this.openingBalances.value.length; i++) {
        this.openingBalances.value[i] = 0;
      }

      for (let keyChild of Object.keys(this.statements.cashInFlow.child)) {
        let cashInFlowChild = this.statements.cashInFlow.child[keyChild];
        for (let cashInFlowChildSub of Object.keys(cashInFlowChild.child)) {
          // utk calculate total row
          let stateChildSubValue = cashInFlowChild.child[cashInFlowChildSub].value;
          cashInFlowChild.child[cashInFlowChildSub].total = stateChildSubValue.reduce((sum, item) => sum + item);
            
          for (let i = 1; i < this.openingBalances.value.length; i++) {
            this.openingBalances.value[i] += stateChildSubValue[i - 1];
            this.statements.totalCashInFlow.value[i - 1
            ] = this.openingBalances.value[i];
          }
          
        }
      }

      for (let i = 0; i < this.openingBalances.value.length; i++) {
        this.netCashSurpluses.value[i] =
          this.statements.totalCashInFlow.value[i] -
          this.statements.totalCashOutFlow.value[i];

        if (i > 0) {
          this.openingBalances.value[i] =
            this.netCashSurpluses.value[i - 1] +
            this.openingBalances.value[i - 1];
        }
      }


    },

    calculateNetCashSurpluses() {
      for (let i = 0; i < this.netCashSurpluses.value.length; i++) {
        this.netCashSurpluses.value[i] = 0;
      }

      for (let keyChild of Object.keys(this.statements.cashOutFlow.child)) {
        let cashOutFlowChild = this.statements.cashOutFlow.child[keyChild];
        let stateChildSubValue = cashOutFlowChild.value;

        cashOutFlowChild.total = stateChildSubValue.reduce(
          (sum, item) => sum + item
        );

        for (let i = 0; i < this.netCashSurpluses.value.length; i++) {
          this.netCashSurpluses.value[i] += stateChildSubValue[i];
          this.statements.totalCashOutFlow.value[
            i
          ] = this.netCashSurpluses.value[i];

          this.statements.totalCashOutFlow.total = cashOutFlowChild.total;
        }
      }

      for (let i = 0; i < this.netCashSurpluses.value.length; i++) {
        this.netCashSurpluses.value[i] =
          this.statements.totalCashInFlow.value[i] -
          this.statements.totalCashOutFlow.value[i];

        if (i > 0) {
          this.openingBalances.value[i] =
            this.netCashSurpluses.value[i - 1] +
            this.openingBalances.value[i - 1];
        }
      }
    },
     onSubmit(value) {
      const data = {
        id: this.fin_id,
        user_id: this.cstmr_id,
        financial_statement_type_id: 5,
        raw: {
          openingBalances: this.openingBalances,
          statements: this.statements,
          netCashSurpluses: this.netCashSurpluses,
        },
      };

      if (this.fin_id) {
        this.store
          .dispatch(Actions.UPDATE_FINANCIAL_STATEMENT, data)
          .then(() => {
            Swal.fire({
              title: "Congratulation!",
              text: "Cash Flow Statement has been updated!",
              icon: "success",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "OK",
            }).then(() => {
              this.router.push({
                path: `/customers/details/${this.cstmr_id}/financial-statements-sme/list`,
              });
            });
          });
      } else {
        this.store
          .dispatch(Actions.STORE_FINANCIAL_STATEMENT, data)
          .then(() => {
            Swal.fire({
              title: "Congratulation!",
              text: "Cash Flow Statement has been saved!",
              icon: "success",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "OK",
            }).then(() => {
              this.router.push({
                path: `/customers/details/${this.cstmr_id}/financial-statements-sme/list`,
              });
            });
          });
      }
    },
  },
});
